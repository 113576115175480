import SurveyService from '@/services/SurveyService'
import { i18n } from '../setup/i18n-setup'
import { extractHttpModuleError } from '@/lib/ErrorExtract'

export default {
  namespaced: true,
  state: {
    survey: {},
  },
  getters: {
    selectSurvey: (state) => state.survey,
  },
  mutations: {
    SET_SURVEY(state, survey) {
      state.survey = survey
    },
    SET_RATING(state, rating) {
      state.survey.rating = rating
    },
    SET_FEEDBACK(state, feedback) {
      state.survey.feedback = feedback
    },
  },
  actions: {
    setSurvey({ commit }, survey) {
      commit('SET_SURVEY', survey)
    },
    async saveSurvey({ dispatch }, { rating }) {
      const loadingId = await dispatch(
        'addToLoader',
        { name: 'SAVE_SURVEY' },
        { root: true },
      )
      let result
      try {
        const response = await SurveyService.saveSurvey({ rating })
        result = response.body.data
      } catch (e) {
        await dispatch(
          'addToErrors',
          {
            name: 'SAVE_SURVEY',
            message: i18n.global.t('common.errors.casual.default_retry'),
          },
          { root: true },
        )
      } finally {
        await dispatch('removeFromLoader', loadingId, { root: true })
        return result
      }
    },
    async modifySurveyRating({ commit, dispatch }, { rating }) {
      const loadingId = await dispatch(
        'addToLoader',
        { name: 'MODIFY_SURVEY_RATING' },
        { root: true },
      )
      let result
      try {
        const response = await SurveyService.modifySurveyRating({ rating })
        commit('SET_RATING', rating)
        result = response.body.data
      } catch (e) {
        await dispatch(
          'addToErrors',
          {
            name: 'MODIFY_SURVEY_RATING',
            message: i18n.global.t('common.errors.casual.default_retry'),
          },
          { root: true },
        )
      } finally {
        await dispatch('removeFromLoader', loadingId, { root: true })
        return result
      }
    },
    async submitFeedback({ commit, dispatch }, { feedback }) {
      const loadingId = await dispatch(
        'addToLoader',
        { name: 'SUBMIT_FEEDBACK' },
        { root: true },
      )
      let result
      try {
        const response = await SurveyService.submitFeedback({ feedback })
        commit('SET_FEEDBACK', feedback)
        result = response.body.data
      } catch (e) {
        await dispatch(
          'addToErrors',
          {
            name: 'SUBMIT_FEEDBACK',
            message: i18n.global.t('common.errors.casual.default_retry'),
          },
          { root: true },
        )
      } finally {
        await dispatch('removeFromLoader', loadingId, { root: true })
        return result
      }
    },
    async createSurvey(
      {},
      { locationId, rating, rawExperienceId, customerId, orderId },
    ) {
      try {
        localStorage.removeItem('marketingOptIn')
        const response = await SurveyService.createSurvey({
          locationId,
          rating: parseInt(rating),
          rawExperienceId,
          customerId,
          orderId,
        })
        localStorage.setItem(
          'marketingOptIn',
          response.body.data.marketingOptIn,
        )
        return response.body
      } catch (e) {
        let Sentry = window?.Sentry || window?.OvationSentry

        let error
        try {
          error = extractHttpModuleError(e)
        } catch (err) {
          error = e
        }

        if (Sentry) {
          Sentry.captureException(error)
        } else {
          console.error('Sentry not found')
        }
        return e.data.data
      }
    },
  },
}
